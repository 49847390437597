import { gql } from "@apollo/client";

export const ORDERS_PENDING = gql`
    query($pageSize: Int,$currentPage:Int, $order_number: String) {
        sellerOrdersList(
            order_number: $order_number,
            action: "list",
            status: "pending",
            pageSize:$pageSize,
            currentPage: $currentPage
        ) {
            items {
                order_number
                item_id
                date
                status
                product_name
                product_sku
                product_image
                price
                qty
                sub_total
                currency
            }
            pageInfo {
                page_size
                current_page
                total_pages
            }
        }
    }
`;

export const ORDERS_CONFIRMED = gql`
    query($pageSize: Int,$currentPage:Int, $orderNumber: String) {
        sellerOrdersList(
            order_number: $orderNumber,
            action: "list",
            status: "accepted",
            pageSize:$pageSize,
            currentPage: $currentPage
        )
        {
            items {
                order_number
                item_id
                date
                status
                product_name
                product_sku
                product_image
                price
                qty
                sub_total
                currency
                waybill_no
                waybill_file
            }
            pageInfo {
                page_size
                current_page
                total_pages
            }
        }
    }
`; 

export const ORDERS_AWB = gql`
    query($pageSize: Int,$currentPage:Int, $orderNumber: String) {
        sellerOrdersList(
                order_number: $orderNumber,
                action: "list",
                status: "awb_generated,pickup_requested",
                pageSize: $pageSize,
                currentPage: $currentPage
        )
        {
            items {
                order_number
                item_id
                date
                status
                product_name
                product_sku
                product_image
                price
                qty
                sub_total
                currency
                waybill_no
                waybill_file
            }
            pageInfo {
                page_size
                current_page
                total_pages
            }
        }
    }
`;

export const ORDERS_SHIPPED = gql`
    query($pageSize: Int,$currentPage:Int, $orderNumber: String) {
        sellerOrdersList(
                order_number: $orderNumber,
                action: "list",
                status: "shipped",
                pageSize: $pageSize,
                currentPage: $currentPage
        )
        {
            items {
                order_number
                item_id
                date
                status
                product_name
                product_sku
                product_image
                price
                qty
                sub_total
                currency
                waybill_no
                waybill_file
            }
            pageInfo {
                page_size
                current_page
                total_pages
            }
        }
    }
`;

export const ORDERS_DELIVERED = gql`
    query($pageSize: Int,$currentPage:Int, $orderNumber: String) {
        sellerOrdersList(
                order_number: $orderNumber,
                action: "list",
                status: "delivered",
                pageSize: $pageSize,
                currentPage: $currentPage
        )
        {
            items {
                order_number
                item_id
                date
                status
                product_name
                product_sku
                product_image
                price
                qty
                sub_total
                currency
                waybill_no
                waybill_file
            }
            pageInfo {
                page_size
                current_page
                total_pages
            }
        }
    }
`;

export const ORDERS_CANCELLED = gql`
    query($pageSize: Int,$currentPage:Int, $orderNumber: String) {
        sellerOrdersList(
                order_number: $orderNumber,
                action: "list",
                status: "canceled,closed,pending_payment,cancel",
                pageSize: $pageSize,
                currentPage: $currentPage
        )
        {
            items {
                order_number
                item_id
                date
                status
                product_name
                product_sku
                product_image
                price
                qty
                sub_total
                currency
                waybill_no
                waybill_file
            }
            pageInfo {
                page_size
                current_page
                total_pages
            }
        }
    }
`;

export const ORDERS_ARCHIVED = gql`
    query($pageSize: Int,$currentPage:Int, $orderNumber: String) {
        sellerOrdersList(
                order_number: $orderNumber,
                action: "list",
                status: "archived",
                pageSize: $pageSize,
                currentPage: $currentPage
        )
        {
            items {
                order_number
                item_id
                date
                status
                product_name
                product_sku
                product_image
                price
                qty
                sub_total
                currency
                waybill_no
                waybill_file
            }
            pageInfo {
                page_size
                current_page
                total_pages
            }
        }
    }
`;